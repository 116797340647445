import React, { useState } from 'react';
import MyPagesPageModel from './Models/MyPagesPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import MyPlanogramsComponent from '../MyPlanogramsPage/MyPlanogramsComponent';
import CustomerInformation from '../CustomerInformation/CustomerInformation';
import { mediaQuery } from '../Theme/mediaQueries';
import OrderHistoryDesktop from '../OrderHistoryComponent/OrderHistoryDesktop';
import OrderHistoryMobile from '../OrderHistoryComponent/OrderHistoryMobile';
import useMedia from '../Shared/Hooks/useMedia';
import MyPlanogramsSeasonalComponent from '../MyPlanogramsSeasonalPage/MyPlanogramsSeasonalComponent';

function MyPagesPage() {
  const { fullName } = useUserStateData();
  const { pageHeading, pageIntroText, breadcrumb } = useCurrentPage<
    MyPagesPageModel
  >();
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  return (
    <main>
      {/* {!!breadcrumb.length && <Breadcrumb breadcrumb={breadcrumb} />} */}
      <TopContent>
        <Heading>
          {pageHeading && pageHeading + ' '}&nbsp;
          {fullName}!
        </Heading>
        <Intro>{pageIntroText}</Intro>
      </TopContent>
      <MyPlanogramsSeasonalComponent showAllSeasonalPlanograms={false} />
      <MyPlanogramsComponent showAllPlanograms={false} />
      <InformationSection>
        <Content>
          <CustomerInformation />
          {isDesktop ? <OrderHistoryDesktop /> : <OrderHistoryMobile />}
        </Content>
      </InformationSection>
    </main>
  );
}

const TopContent = styled.div({
  width: '100%',
  color: theme.black,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(15),
  maxWidth: theme.blockMaxWidthMobile,
  padding: {
    x: '7.5px',
  },
  paddingTop: theme.spacing(10),
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { x: 'auto' },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { y: theme.spacing(14) },
    padding: { x: theme.medium, y: theme.none },
    maxWidth: '900px',
  }),
});

const InformationSection = styled.div({
  maxWidth: theme.screenMaxWidth,
  margin: { x: 'auto' },
  ...media(mediaQuery.mediaMinLarge, {
    padding: { x: theme.spacing(10) },
  }),
});

const Heading = styled.h1({
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: '32px',
    lineHeight: '40px',
    marginTop: theme.spacing(5),
  }),
});

const Intro = styled.p({
  lineHeight: '28.8px',
});

const Content = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: { x: '40px' },
  backgroundColor: theme.white,
  padding: { x: theme.moreLarge },
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(10),
  borderRadius: '10px',
  ...media(mediaQuery.mediaMinLarge, {
    padding: { x: theme.spacing(3) },
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(16),
    maxWidth: theme.screenMaxWidth,
    margin: { x: 'auto' },
  }),
  ...media(mediaQuery.mediaMaxLarge, {
    flexDirection: 'column',
    margin: { x: '7.5px' },
  }),
});

export default MyPagesPage;
