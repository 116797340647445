import React, { useEffect, useState } from 'react';

import { styled, theme } from '../Theme';
import { mediaQuery } from '../Theme/mediaQueries';
import CtaButton from '../Shared/CtaButton/CtaButton';
import { media, pseudo } from '@glitz/core';
import ShoppingBasketIconBold from '../Shared/Icons/ShoppingBasketIconBold';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import OrderRow from './OrderRow';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import { GetOrderHistory } from '../OrderHistory/Order';
import OrderInformation from '../OrderHistory/OrderInformation';
import { LoadingCircle } from '../Shared/Icons';
import Dots from '../Shared/Button/Dots';

let pageIndex = 1;

function OrderHistoryDesktop() {
  const {
    translations: {
      'order/myOrders/orderDate': orderDateLabel,
      'order/myOrders/orderNumber': orderNumberLabel,
      'order/myOrders/status': statusLabel,
      'order/myOrders/totalPrice': totalPriceLabel,
      'order/myOrders/viewMore': viewMoreLabel,
      'order/myOrders/myOrder': myOrderLabel,
      'order/myOrders/noOrders': noOrdersLabel,
    },
    languageRoute,
    requestToken,
  } = useAppSettingsData();
  const { customerId } = useUserStateData();

  const { channelId } = useCurrentPage<PageModelBase>();
  const url = `/api/${languageRoute}/order/GetOrderHistory?customerId=${customerId}&pageIndex=1&pageSize=10`;
  const [orderHistory, setOrderHistory] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  useEffect(() => {
    customerId &&
      GetOrderHistory(channelId, requestToken, url, setIsLoading).then(data => {
        data && setOrderHistory(data.orders);
        data && setOrderCount(data.pagination.totalCount);
      });
  }, [channelId]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();

  const onOrderClick = (orderId: string) => {
    setIsOpen(true);
    orderHistory.map(
      (order: any) =>
        order.externalOrderID === orderId && setSelectedOrder(order)
    );
  };

  async function loadMore() {
    const url = `/api/${languageRoute}/order/GetOrderHistory?customerId=${customerId}&pageIndex=${pageIndex +
      1}&pageSize=10`;
    const data = await GetOrderHistory(
      channelId,
      requestToken,
      url,
      setIsLoadingMore
    );
    let newArr = data && orderHistory.concat(data.orders);
    setOrderHistory(newArr);
    pageIndex++;
  }

  const [orderCount, setOrderCount] = useState<number>(0);

  const disableButton = orderHistory && orderHistory.length >= orderCount;

  return (
    <>
      <OrderHistoryDesktopComponent>
        {!isOpen ? (
          <>
            <HeadingSection>
              <Heading>
                <StyledShoppingBasketIconBold />
                {myOrderLabel}
              </Heading>
            </HeadingSection>
            {orderHistory && !!orderHistory.length ? (
              <>
                <OrderhistoryArrayDesktop>
                  <ArrayDesktopHeading>
                    <ListItemTitleDesktop>
                      {orderDateLabel}
                    </ListItemTitleDesktop>
                    <ListItemTitleDesktop data-large={true}>
                      {orderNumberLabel}
                    </ListItemTitleDesktop>
                    <ListItemTitleDesktop>
                      {totalPriceLabel}
                    </ListItemTitleDesktop>
                    <ListItemTitleDesktop>{statusLabel}</ListItemTitleDesktop>
                  </ArrayDesktopHeading>
                  {orderHistory.map((order: any) => (
                    <OrderRow
                      key={order.orderId}
                      order={order}
                      onClick={() => onOrderClick(order.externalOrderID)}
                    />
                  ))}
                </OrderhistoryArrayDesktop>
                <ButtonWrapper>
                  <StyledCtaButton
                    onClick={() => loadMore()}
                    disabled={disableButton}
                  >
                    <ButtonText data-isloading={isLoadingMore}>
                      {viewMoreLabel}
                    </ButtonText>
                    {isLoadingMore && (
                      <StyledLoadingCircle isLoading={isLoadingMore} />
                    )}
                  </StyledCtaButton>
                </ButtonWrapper>
              </>
            ) : !isLoading ? (
              <div>{noOrdersLabel}</div>
            ) : (
              <DotsWrapper>
                <Dots />
              </DotsWrapper>
            )}
          </>
        ) : (
          <OrderInformation
            order={selectedOrder}
            onClick={() => setIsOpen(false)}
          />
        )}
      </OrderHistoryDesktopComponent>
    </>
  );
}

const OrderHistoryDesktopComponent = styled.div({
  backgroundColor: theme.primaryBackground,
  padding: { xy: theme.theta },
  borderRadius: '10px',
  width: '55%',
  ...media(mediaQuery.mediaMaxLarge, {
    display: 'none',
  }),
});

const HeadingSection = styled.div({
  display: 'flex',
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const ButtonText = styled.span({
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isloading="true"]'], {
    visibility: 'hidden',
    opacity: 0,
  }),
});

const Heading = styled.h2({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.zeta,
  margin: { bottom: theme.spacing(11) },
  color: theme.black,
  ...media(mediaQuery.mediaMaxVerySmall, {
    fontSize: theme.larger,
    margin: { bottom: '50px' },
  }),
});

const DotsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(9),
});

const StyledShoppingBasketIconBold = styled(ShoppingBasketIconBold, {
  marginRight: theme.spacing(5),
});

const OrderhistoryArrayDesktop = styled.div({
  display: 'table',
  width: '100%',
  borderCollapse: 'collapse',
});

const ArrayDesktopHeading = styled.div({
  display: 'table-row',
});

const ListItemTitleDesktop = styled.span({
  display: 'table-cell',
  lineHeight: '22.4px',
  fontSize: theme.beta,
  color: theme.breadText,
  paddingBottom: theme.spacing(3),
  ...pseudo([':nth-child(n)[data-large="true"]'], {
    width: '35%',
  }),
});

const ButtonWrapper = styled.div({
  display: 'flex',
});

const StyledCtaButton = styled(CtaButton, {
  position: 'relative',
  marginTop: '33px',
  padding: { x: '63px' },
  ...media(mediaQuery.mediaMaxLarge, {
    margin: { x: 'auto' },
  }),
  ':disabled': {
    backgroundColor: theme.gray,
  },
});

export default OrderHistoryDesktop;
