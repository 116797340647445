import React, { useState } from 'react';

import Divider from '../Shared/Divider/Divider';
import { styled, theme } from '../Theme';
import { mediaQuery } from '../Theme/mediaQueries';
import { media, pseudo } from '@glitz/core';

type PropType = {
  onClick?: () => void;
  order: any;
};

function OrderRow({ onClick, order }: PropType) {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <ArrayItems>
        <ArrayOrderItem>{order.orderDate && order.orderDate}</ArrayOrderItem>
        <ArrayOrderNumber onClick={onClick}>
          {order.externalOrderID}
        </ArrayOrderNumber>
        <ArrayOrderItem>{order.orderGrandTotal}</ArrayOrderItem>
        <ArrayOrderItem>{order.status}</ArrayOrderItem>
      </ArrayItems>
      {/* <StyledDivider /> */}
    </>
  );
}

const ArrayItems = styled.div({
  display: 'table-row',
  lineHeight: '25.6px',
  borderTopColor: theme.grayLine,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  ...media(mediaQuery.mediaMaxSmall, {
    display: 'none',
  }),
});

const ArrayOrderItem = styled.span({
  display: 'table-cell',
  fontSize: theme.gamma,
  color: theme.breadText,
  // lineHeight: '25.6px',
  padding: { y: theme.spacing(3) },
});

const ArrayOrderNumber = styled.span({
  display: 'table-cell',
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  // lineHeight: '25.6px',
  textDecoration: 'underline',
  padding: { y: theme.spacing(3) },
});

const StyledDivider = styled(Divider, {
  width: '100%',
  backgroundColor: theme.grayLine,
  margin: { y: theme.delta },
});

export default OrderRow;
