import React, { useEffect, useState, useRef } from 'react';

import { styled, theme } from '../Theme';
import { mediaQuery } from '../Theme/mediaQueries';
import CtaButton from '../Shared/CtaButton/CtaButton';
import { media, pseudo } from '@glitz/core';
import ShoppingBasketIconBold from '../Shared/Icons/ShoppingBasketIconBold';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import { GetOrderHistory } from '../OrderHistory/Order';
import OrderRow from './OrderRow';
import { LoadingCircle } from '../Shared/Icons';
import OrderRowMobile from './OrderRowMobile';
import useMedia from '../Shared/Hooks/useMedia';
import OrderInformationMobile from '../OrderHistory/OrderInformationMobile';
import Dots from '../Shared/Button/Dots';

let pageIndex = 1;

function OrderHistoryMobile() {
  const {
    translations: {
      'order/myOrders/orderDate': orderDateLabel,
      'order/myOrders/orderNumber': orderNumberLabel,
      'order/myOrders/status': statusLabel,
      'order/myOrders/totalPrice': totalPriceLabel,
      'order/myOrders/viewMore': viewMoreLabel,
      'order/myOrders/myOrder': myOrderLabel,
      'order/myOrders/noOrders': noOrdersLabel,
    },
    languageRoute,
    requestToken,
  } = useAppSettingsData();
  const { customerId } = useUserStateData();

  const { channelId } = useCurrentPage<PageModelBase>();
  const url = `/api/${languageRoute}/order/GetOrderHistory?customerId=${customerId}&pageIndex=1&pageSize=10`;
  const [orderHistory, setOrderHistory] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    customerId &&
      GetOrderHistory(channelId, requestToken, url, setIsLoading).then(
        data => data && setOrderHistory(data.orders)
      );
  }, [channelId]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();

  const onOrderClick = (orderId: string) => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
    });
    setIsOpen(true);
    orderHistory.map(
      (order: any) =>
        order.externalOrderID === orderId && setSelectedOrder(order)
    );
  };

  async function loadMore() {
    const url = `/api/${languageRoute}/order/GetOrderHistory?customerId=${customerId}&pageIndex=${pageIndex +
      1}&pageSize=10`;
    const data = await GetOrderHistory(
      channelId,
      requestToken,
      url,
      setIsLoadingMore
    );
    let newArr = data && orderHistory.concat(data.orders);
    setOrderHistory(newArr);
    pageIndex++;
  }

  const isDesktop = useMedia(theme.mediaQuery.mediaMinSmall);

  return (
    <>
      <CustomerInformationComponent ref={ref}>
        {!isOpen ? (
          <>
            <HeadingSection>
              <Heading>
                <StyledShoppingBasketIconBold />
                {myOrderLabel}
              </Heading>
            </HeadingSection>
            {orderHistory && !!orderHistory.length ? (
              <>
                <OrderhistoryArray>
                  <ArrayHeading>
                    <ListItemTitleDesktop>
                      {orderDateLabel}
                    </ListItemTitleDesktop>
                    <ListItemTitleDesktop data-large={true}>
                      {orderNumberLabel}
                    </ListItemTitleDesktop>
                    <ListItemTitleDesktop>
                      {totalPriceLabel}
                    </ListItemTitleDesktop>
                    <ListItemTitleDesktop>{statusLabel}</ListItemTitleDesktop>
                  </ArrayHeading>
                  {orderHistory.map((order: any) =>
                    isDesktop ? (
                      <OrderRow
                        key={order.orderId}
                        order={order}
                        onClick={() => onOrderClick(order.externalOrderID)}
                      />
                    ) : (
                      <OrderRowMobile
                        key={order.orderId}
                        order={order}
                        onClick={() => onOrderClick(order.externalOrderID)}
                      />
                    )
                  )}
                </OrderhistoryArray>
                <ButtonWrapper>
                  <StyledCtaButton onClick={() => loadMore()}>
                    <ButtonText data-isloading={isLoadingMore}>
                      {viewMoreLabel}
                    </ButtonText>
                    {isLoadingMore && (
                      <StyledLoadingCircle isLoading={isLoadingMore} />
                    )}
                  </StyledCtaButton>
                </ButtonWrapper>
              </>
            ) : !isLoading ? (
              <div>{noOrdersLabel}</div>
            ) : (
              <DotsWrapper>
                <Dots />
              </DotsWrapper>
            )}
          </>
        ) : (
          <OrderInformationMobile
            order={selectedOrder}
            onClick={() => setIsOpen(false)}
          />
        )}
      </CustomerInformationComponent>
    </>
  );
}

const CustomerInformationComponent = styled.div({
  backgroundColor: theme.primaryBackground,
  padding: { x: theme.spacing(3), y: theme.spacing(7) },
  borderRadius: '10px',
  width: '100%',
  scrollMarginTop: '125px',
  ...media(mediaQuery.mediaMaxLarge, {
    padding: { x: theme.spacing(4) },
    paddingBottom: theme.spacing(6),
  }),
});

const HeadingSection = styled.div({ paddingLeft: theme.spacing(1) });

const Heading = styled.h2({
  display: 'flex',
  alignItems: 'center',
  fontSize: '22px',
  margin: { bottom: theme.spacing(11) },
  color: theme.black,
  ...media(mediaQuery.mediaMaxSmall, {
    marginBottom: theme.moreLarge,
  }),
});

const DotsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(9),
});

const StyledShoppingBasketIconBold = styled(ShoppingBasketIconBold, {
  marginRight: theme.spacing(4),
});

const ButtonWrapper = styled.div({
  display: 'flex',
});

const ButtonText = styled.span({
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isloading="true"]'], {
    visibility: 'hidden',
    opacity: 0,
  }),
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const OrderhistoryArray = styled.div({
  display: 'table',
  width: '100%',
  borderCollapse: 'collapse',
});

const ArrayHeading = styled.div({
  display: 'table-row',
  ...media(mediaQuery.mediaMaxSmall, {
    display: 'flex',
    flexDirection: 'column',
  }),
});

const ListItemTitleDesktop = styled.span({
  display: 'table-cell',
  lineHeight: '22.4px',
  fontSize: theme.beta,
  color: theme.breadText,
  paddingBottom: theme.spacing(3),
  ...pseudo([':nth-child(n)[data-large="true"]'], {
    width: '35%',
  }),
  ...media(mediaQuery.mediaMaxSmall, {
    display: 'none',
  }),
});

const StyledCtaButton = styled(CtaButton, {
  position: 'relative',
  marginTop: '33px',
  padding: { x: '63px' },
  ...media(mediaQuery.mediaMaxLarge, {
    margin: { x: 'auto' },
  }),
});

export default OrderHistoryMobile;
