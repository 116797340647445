import React, { useState } from 'react';

import { styled, theme } from '../Theme';
import { mediaQuery } from '../Theme/mediaQueries';
import { media, pseudo } from '@glitz/core';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

type PropType = {
  onClick?: () => void;
  order: any;
};

function OrderRowMobile({ onClick, order }: PropType) {
  const {
    translations: {
      'order/myOrders/orderDate': orderDateLabel,
      'order/myOrders/orderNumber': orderNumberLabel,
      'order/myOrders/status': statusLabel,
      'order/myOrders/totalPrice': totalPriceLabel,
    },
  } = useAppSettingsData();

  return (
    <>
      <Content>
        <Array data-whitebackground="true">
          <ArrayOrderItemHeading>{orderDateLabel}</ArrayOrderItemHeading>
          <ArrayOrderItem>{order.orderDate && order.orderDate}</ArrayOrderItem>
        </Array>
        <Array>
          <ArrayOrderItemHeading>{orderNumberLabel}</ArrayOrderItemHeading>
          <ArrayOrderNumber onClick={onClick}>
            {order.externalOrderID}
          </ArrayOrderNumber>
        </Array>
        <Array data-whitebackground="true">
          <ArrayOrderItemHeading>{totalPriceLabel}</ArrayOrderItemHeading>
          <ArrayOrderItem>{order.orderGrandTotal}</ArrayOrderItem>
        </Array>
        <Array>
          <ArrayOrderItemHeading>{statusLabel}</ArrayOrderItemHeading>
          <ArrayOrderItem>{order.status}</ArrayOrderItem>
        </Array>
      </Content>
    </>
  );
}

const Content = styled.div({
  borderTopColor: theme.grayLine,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  margin: { y: theme.spacing(5) },
  paddingTop: theme.spacing(6),
});

const Array = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '22.4px',
  padding: { x: theme.spacing(4), y: '10px' },
  ...pseudo([':nth-child(n)[data-whitebackground="true"]'], {
    backgroundColor: theme.white,
  }),
});

const ArrayOrderItemHeading = styled.span({
  fontSize: theme.gamma,
  color: theme.breadText,
});

const ArrayOrderItem = styled.span({
  fontSize: theme.gamma,
  color: theme.black,
  lineHeight: '25.6px',
});

const ArrayOrderNumber = styled.span({
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
});

export default OrderRowMobile;
